export const getSizeAccType = (currentSize = '', configSize = '') => {
    if (configSize === 'n') {
        return currentSize;
    }
    else {
        const currentConfig = configSize;
        const currentSizeInNumber = Number(currentSize.replace('px', ''));
        switch (currentConfig) {
            case 'n-3':
                return `${currentSizeInNumber - 3}px`
            case 'n-1':
                return `${currentSizeInNumber - 1}px`
            case 'n+1':
                return `${currentSizeInNumber + 1}px`
            case 'n+3':
                return `${currentSizeInNumber + 3}px`
        }
    }
}

export const getIconSize = (configSize = '') => {
    switch(configSize) {
        case 'n-3':
            return `spriteDetail1`
        case 'n-1':
            return `spriteDetail2`
        case 'n': 
            return `spriteDetail`
        case 'n+1':
            return `spriteDetail3`
        case 'n+3':
            return `spriteDetail4`
    }
}

export const getIconSizeAccToConfig = (configSize = '') => {
    switch(configSize) {
        case 'n-3':
            return `16`
        case 'n-1':
            return `20`
        case 'n': 
            return `24`
        case 'n+1':
            return `28`
        case 'n+3':
            return `32`
    }
}