'use client';

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './megaMenu.module.scss';
import RenderCategory from './RenderCategory';
import { routeToCollectionPage } from '@/utils/routeToCollectionPage';
import { useSSRSelector } from '@/redux/ssrStore';
import Masonry from 'react-responsive-masonry';
import { ClickAwayListener } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import { useNavlinkStyling } from 'src/hooks/useNavlinkStyling';
import { merge } from 'lodash';

export const MegaMenuDrawerDesktop = ({
  open,
  onClose,
  forNewHeader,
  customStylist = {},
  _RenderUnderlineComponent,
}) => {
  const { megaMenuStyles = {}, isScroll } = customStylist as any;

  const { collections } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
  }));
  const [collectionHoverIndex, setCollectionHoverIndex] = useState(null);
  // For parent
  const { onLinkHoverEnter, onLinkHoverLeave, getNavElementStyle } = useNavlinkStyling({
    isScrollable: isScroll,
  });
  // For dropdown
  const customLinkStyling = useNavlinkStyling({
    isScrollable: isScroll,
  });

  useEffect(() => {
    if (open) {
      document.body.classList.add('tw-overflow-y-hidden');
      // document.body.classList.remove('tw-overflow-y-auto');
    } else {
      // document.body.classList.add('tw-overflow-y-auto');
      document.body.classList.remove('tw-overflow-y-hidden');
    }
    return () => {
      // document.body.classList.add('tw-overflow-y-auto');
      document.body.classList.remove('tw-overflow-y-hidden');
    };
  }, [open]);

  const renderCollectionCategories = () => {
    const numColumns = collections.length >= 4 ? 4 : collections.length;
    return (
      <div
        className={twMerge(
          style.megaMenuScrollStyle,
          'tw-m-auto tw-flex tw-h-[100%] tw-max-w-[750px] tw-flex-wrap tw-justify-center tw-overflow-y-auto tw-pr-[14px]'
        )}
      >
        <Masonry columnsCount={numColumns} gutter="34px">
          {collections.map((collection, index) => {
            return (
              <div
                key={collection.id}
                className="tw-relative tw-flex tw-w-[100%] tw-select-none tw-flex-col "
                onMouseEnter={() => setCollectionHoverIndex(index)}
                onMouseLeave={() => setCollectionHoverIndex(-1)}
              >
                {/* <div className={styles.collectionImage}>{collection.image_url ? (<img src={collection.image_url} />) : (<CollectionWithNoImage />)}</div> */}
                <p
                  onMouseEnter={() => onLinkHoverEnter(index)}
                  onMouseLeave={onLinkHoverLeave}
                  className={twMerge(
                    'tw-relative tw-m-0 tw-mb-[12px] tw-max-w-fit tw-cursor-pointer tw-break-all tw-text-[12px] tw-font-bold tw-uppercase tw-leading-[15.62px] tw-tracking-[1px]',
                    '[&>.underline-comp]:hover:tw-block'
                  )}
                  onClick={() => {
                    routeToCollectionPage(collection);
                    onClose();
                  }}
                  style={{ ...getNavElementStyle(index), fontWeight: '700' }}
                >
                  {collection.name}
                  {_RenderUnderlineComponent?.(index, {
                    bottom: -4,
                    width: '100%',
                    top: undefined,
                    height: '1px',
                  })}
                </p>
                <RenderCategory
                  collectionId={collection.id}
                  categories={collection.categories}
                  onClose={onClose}
                  showMoreCount={4}
                  forNewHeader={forNewHeader}
                  customLinkStyling={customLinkStyling}
                  parentIndex={index}
                  isParentCategoryHovered={collectionHoverIndex === index}
                  _RenderUnderlineComponent={_RenderUnderlineComponent}
                />
              </div>
            );
          })}
        </Masonry>
      </div>
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <div
          className={cn(
            'tw-font-dmSans',
            'tw-absolute tw-left-0 tw-right-0 tw-top-[100%] tw-z-[12] tw-h-[370px] tw-min-h-[200px] tw-bg-white tw-px-[20px] tw-py-[28px]',
            open ? 'tw-block' : 'tw-hidden'
          )}
          style={merge(megaMenuStyles, {
            borderTop: `1px solid ${!megaMenuStyles.background || ['#fff', '#ffffff'].includes(megaMenuStyles.background) ? '#111C361F' : '#ffffff4d'}`,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {renderCollectionCategories()}
        </div>
      </ClickAwayListener>
    </>
  );
};
