import { getSizeAccType } from 'src/components/DesignSystem/Widgets2.0/Header/components/getSizeAccType';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useGetDeviceType } from './useGetDeviceType';
import { useState } from 'react';

export function useNavlinkStyling(props = {} as any) {
  const { isScrollable = false, parentHoverIndex = -1 } = props;
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const [hoverIndex, setHoverIndex] = useState(null);
  const { getWidgetSSRState } = useWidgetDndContextData();
  const { activeHeaderData } = getWidgetSSRState();

  const globalClass = activeHeaderData?.headerPropsConfig?.globalClass || {};
  const { staticClass, scrollClass } = globalClass || {};

  const isTransparent =
    globalClass?.staticClass?.base_config?.defaultFillColorType === 'transparent';

  const currentClass = isScrollable ? scrollClass : staticClass;

  const selectedClass = isTransparent ? currentClass : globalClass?.staticClass;

  function getHoverTextColor() {
    const type = selectedClass?.base_config?.defaulthoverType;
    switch (type) {
      case 'Underline':
        return selectedClass?.base_config?.defaultUnderlineTextColor;
      case 'Background Box':
        return selectedClass.base_config?.defaultBoxTextColor;
      case 'None':
        return selectedClass?.base_config?.defaultNavLinksColor;
    }
  }

  function getNavElementStyle(index: number, parentIndex = -1, isHoverEnabled = true) {
    const noParentSet = parentHoverIndex === -1;
    const isParentHovered = parentIndex === parentHoverIndex;

    return {
      fontSize: getSizeAccType(
        globalClass?.staticClass?.base_config?.defaultTextFontSize,
        isMobile
          ? globalClass?.staticClass?.base_config?.defaultFontSizeTypeMobile
          : globalClass?.staticClass?.base_config?.defaultFontSizeType
      ),
      fontWeight:
        hoverIndex === index
          ? selectedClass?.base_config?.defaultTextFontWeightOnHover
          : globalClass?.staticClass?.base_config?.defaultTextFontWeight,
      color:
        (isHoverEnabled && (noParentSet || isParentHovered) && hoverIndex === index
          ? getHoverTextColor()
          : selectedClass?.base_config?.defaultNavLinksColor) || '#000',
      textTransform:
        globalClass?.staticClass?.base_config?.defaultTextDecoration || 'capitalize',
      ...(isHoverEnabled &&
      (noParentSet || isParentHovered) &&
      hoverIndex === index &&
      selectedClass?.base_config?.defaulthoverType === 'Background Box'
        ? {
            width: 'max-content',
            backgroundColor: selectedClass?.base_config?.defaultBoxBackgroundColor,
          }
        : {}),
    };
  }

  const onLinkHoverEnter = (index) => {
    setHoverIndex(index);
  };

  const onLinkHoverLeave = () => {
    setHoverIndex(null);
  };

  return { onLinkHoverLeave, onLinkHoverEnter, getNavElementStyle, hoverIndex };
}
